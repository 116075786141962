import React, { createRef, useEffect, useState } from "react";
import { ATTEMPTED_BUR_BADGER_TYPE, BADGER_TYPE, useAppState } from "./state";
import {
  ColumnFlex,
  LabelHeading,
  RowFlex,
  SubText,
  TriText,
  Text,
} from "./styled";
import styled, { keyframes } from "styled-components";
import Colors from "../const/colors";

import heartL from "../assets/HeartVectorTile.svg";
import actionHeart from "../assets/actionHeart.svg";
import BigHeart from "../assets/CongratsHeart.svg";

import { ToastContainer, toast } from "react-toastify";
import { right } from "styled-system";

export const Tiles = styled.div`
  padding: 1vh 16vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

export const Tile = styled.div`
  margin: 15px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 100;
  width: 18vw;
  height: 18vw;
  animate: all ease 0.2s;
  padding: 8px;
  position: relative;

  img {
    width: 100%;
    transition: transform 500ms ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &:hover {
    .InfoTileButton {
      opacity: 1;
    }
  }
`;

export const InfoTileButton = styled.div`
  transition: transform 500ms ease;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ff5faf;
  opacity: 0;
  z-index: 40;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  :hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  }
  :active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }
`;
export const SmolTile = styled.div`
  cursor: pointer;
  overflow: hidden;
  width: 150px;
  height: 150px;
  animate: all ease 0.2s;
  padding: 8px;
  position: relative;
  border: solid 2px ${Colors.brightPink};
  border-radius: 12px;

  img {
    width: 100%;
    transition: transform 500ms ease;
  }
`;

const SmolKeysframes = keyframes`
0% {transform: scale(1);}
  25% {transform: scale(.97);}
  35% {transform: scale(.9);}
  45% {transform: scale(1.1);}
  55% {transform: scale(.9);}
  65% {transform: scale(1.1);}
  75% {transform: scale(1.03);}
  100% {transform: scale(1);} 
`;

const SmolImg = styled.img`
  width: 100%;
  transition: transform 500ms ease;
  animation-name: ${SmolKeysframes};
  animation-duration: 2s;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;

const SmolKeysframesAlter = keyframes`
0% {transform: scale(.8);}
  25% {transform: scale(.77);}
  35% {transform: scale(.7);}
  45% {transform: scale(.8);}
  55% {transform: scale(.7);}
  65% {transform: scale(.83);}
  75% {transform: scale(.81);}
  100% {transform: scale(.8);} 
`;

const SmolImgAlter = styled.img`
  width: 100%;
  transition: transform 100ms ease;
  animation-name: ${SmolKeysframesAlter};
  animation-duration: 3s;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;
type SmolTileImageProps = {
  badger: BADGER_TYPE | null;
};
export const SmolTileImage = (props: SmolTileImageProps) => {
  const { badger } = props;

  return (
    <SmolTile>
      {badger ? (
        <img
          style={{
            filter: "brightness(100%)",
          }}
          src={badger.imageUrl}
          alt={badger.name}
        />
      ) : (
        <SmolImgAlter
          style={{
            filter: "brightness(100%)",
          }}
          src={heartL}
          alt="Baby Badgers"
        />
      )}
    </SmolTile>
  );
};

type uwuProps = {
  top: string;
  bottom: string;
};
export const BadgerTraitInfoColumn = ({ top, bottom }: uwuProps) => {
  return (
    <RowFlex margin={"6px"} width="100%">
      <ColumnFlex>
        <TriText color="black">{top}</TriText>
        <TriText color="black" fontWeight={"800"}>
          {bottom}
        </TriText>
      </ColumnFlex>
    </RowFlex>
  );
};
type InfoBadgerProps = {
  badger: BADGER_TYPE;
  closeToast: (() => void) | undefined;
};
export const InfoBadger = (props: InfoBadgerProps) => {
  const { badger } = props;

  return (
    <ColumnFlex padding={2} width={"100%"} alignItems={"center"}>
      <img
        style={{
          filter: "brightness(100%)",
          borderRadius: "50%",
          height: "80px",
          width: "80px",
        }}
        src={badger.imageUrl}
        alt={badger.name}
      />

      <LabelHeading>{`Badger #${props.badger.id}`}</LabelHeading>

      {badger.attributes.map((d, i) => {
        return <BadgerTraitInfoColumn top={d.trait_type} bottom={d.value} />;
      })}
    </ColumnFlex>
  );
};

type TileImageProps = {
  badger: BADGER_TYPE;

  BADGER_0: BADGER_TYPE | null;
  BADGER_1: BADGER_TYPE | null;

  alreadyBreed: ATTEMPTED_BUR_BADGER_TYPE | undefined;
  pending: ATTEMPTED_BUR_BADGER_TYPE | undefined;
  handleSelectBadger: (badger: BADGER_TYPE) => void;
  handleBadgerInfoClick: (badger: BADGER_TYPE) => void;
};
export const TileImage = (props: TileImageProps) => {
  const {
    badger,
    BADGER_0,
    BADGER_1,
    handleSelectBadger,
    alreadyBreed,
    pending,
  } = props;

  const hanldeUpdatingInfobadger = () => {
    props.handleBadgerInfoClick(badger);
  };
  const handlePreventingCLick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    hanldeUpdatingInfobadger();
  };

  const selected =
    (BADGER_0 && BADGER_0.id === badger.id) ||
    (BADGER_1 && BADGER_1.id === badger.id);
  return (
    <Tile
      onClick={() => handleSelectBadger(badger)}
      style={{
        position: "relative",
        border: selected
          ? `solid 8px ${Colors.black}`
          : `solid 8px ${Colors.lightPink}`,
      }}
    >
      {alreadyBreed && (
        <div
          style={{
            padding: "0px 6px",
            height: "50px",
            backgroundColor: "#C1FFCB",
            borderRadius: "5px",
            position: "absolute",
            top: "20px",
            right: "0",
            left: "0",
            margin: "auto",
            zIndex: 20,
            maxWidth: "230px",
          }}
        >
          <Text fontWeight={600} color={"#008416"} textAlign={"center"}>
            Honeymooning...
          </Text>
        </div>
      )}
      {alreadyBreed === undefined && pending && (
        <div
          style={{
            padding: "0px 6px",
            height: "50px",
            backgroundColor: "#FFFDC1",
            borderRadius: "5px",
            position: "absolute",
            top: "20px",
            right: "0",
            left: "0",
            margin: "auto",
            zIndex: 19,
            maxWidth: "230px",
          }}
        >
          <Text fontWeight={600} color={"#847F00"} textAlign={"center"}>
            Date In Progress
          </Text>
        </div>
      )}
      <InfoTileButton
        onClick={handlePreventingCLick}
        className="InfoTileButton"
        style={{
          transition: "all ease .2s",
        }}
      >
        <LabelHeading textAlign={"center"} color="white">
          i
        </LabelHeading>
      </InfoTileButton>
      <img
        style={{
          filter: "brightness(100%)",
        }}
        src={props.badger.imageUrl}
        alt={props.badger.name}
      />
    </Tile>
  );
};

type ActionHeartProps = {
  handleShowTraitSelection: () => void;
};
export const ActionHeart = (props: ActionHeartProps) => {
  return (
    <SmolTile
      onClick={() => props.handleShowTraitSelection()}
      style={{ border: "none", position: "relative" }}
    >
      <div
        style={{
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: 20,
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            maxWidth: "100px",
            textAlign: "center",
            marginBottom: "30px",
            lineHeight: "30px",
          }}
          fontWeight={600}
          color={Colors.burgundy}
        >
          Start Date
        </Text>
      </div>
      <SmolImg
        style={{
          filter: "brightness(100%)",
        }}
        src={actionHeart}
        alt="Baby Badgers"
      />
    </SmolTile>
  );
};

type DietCoke = {
  _showCongrats: (status: boolean) => void;
  _txId: (txID: null) => void;
  txId: string | null;
};

export const CongratsView = (props: DietCoke) => {
  const { pendingBadgers } = useAppState();
  const { txId } = props;

  const handleClickOut = () => {
    props._showCongrats(false);
    props._txId(null);
  };

  const handleViewTx = () => {
    console.log("wtf");
    if (txId) {
      window.open(`https://explorer.stacks.co/txid/${txId}?chain=mainnet`);
    }
  };

  return (
    <ColumnFlex
      style={{
        height: "80vh",
        width: "100vw",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <ColumnFlex
        overflow={"hidden"}
        height={"75vh"}
        maxHeight={"800px"}
        alignItems="center"
        width={"100vw"}
        justifyContent="center"
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            margin: "auto",
            height: "55vh",
            maxWidth: "800px",
            top: "40px",
            width: "100vw",
            maxHeight: "800px",
            zIndex: 8,
            padding: 0,
          }}
        >
          <img
            style={{
              filter: "brightness(100%)",
              height: "90%",

              width: "100%",
            }}
            src={BigHeart}
            alt="love"
          />
        </div>

        {pendingBadgers.length > 1 && (
          <RowFlex
            style={{
              zIndex: 10,
            }}
          >
            <div
              style={{
                margin: "0px",
              }}
            >
              <img
                style={{
                  opacity: 0.6,
                  borderRadius: "50%",
                  height: "200px",
                  width: "200px",
                }}
                src={pendingBadgers[pendingBadgers.length - 1].imageUrl}
                alt={pendingBadgers[pendingBadgers.length - 1].name}
              />
            </div>
            <div
              style={{
                margin: "0px",
              }}
            >
              <img
                style={{
                  opacity: 0.6,
                  borderRadius: "50%",
                  height: "200px",
                  width: "200px",
                }}
                src={pendingBadgers[pendingBadgers.length - 2].imageUrl}
                alt={pendingBadgers[pendingBadgers.length - 2].name}
              />
            </div>
          </RowFlex>
        )}
      </ColumnFlex>
      <LabelHeading
        textAlign={"center"}
        style={{ maxWidth: "420px", marginTop: "20px" }}
        color={Colors.brightPink}
      >
        A match made in heaven
      </LabelHeading>
      <SubText
        padding="10px 20px"
        color={Colors.brightPink}
        textAlign={"center"}
        lineHeight={"32px"}
        style={{
          maxWidth: "400px",
        }}
      >
        Once confirmed in an anchor block you will not be able to change these
        or use these badgers for other Valentines
      </SubText>

      <RowFlex>
        <div
          onClick={() => handleClickOut()}
          style={{
            borderRadius: "56px",
            marginRight: "20px",
            padding: "5px 20px",
            backgroundColor: Colors.brightPink,
            cursor: "pointer",
          }}
        >
          <SubText textAlign={"center"} color={"white"} fontWeight={400}>
            Match other badgers
          </SubText>
        </div>
        <div
          style={{
            borderRadius: "56px",
            padding: "5px 20px",
            border: `solid 2px ${Colors.brightPink}`,
            cursor: "pointer",
          }}
        >
          <SubText
            textAlign={"center"}
            color={Colors.brightPink}
            fontWeight={400}
            onClick={() => handleViewTx()}
          >
            View in explorer
          </SubText>
        </div>
      </RowFlex>
    </ColumnFlex>
  );
};

export const TileBasic = styled.div`
  margin: 0 15px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 100;
  min-width: 150px;
  max-width: 200px;
  min-height: 150px;
  max-height: 200px;

  width: 30vw;
  height: 30vw;

  background-color: white;

  animate: all ease 0.2s;
  padding: 0 8px;
  position: relative;

  img {
    width: 14vw;
    height: 14vw;
    min-width: 100px;
    max-width: 200px;
    min-height: 100px;
    max-height: 200px;
    transition: transform 500ms ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &:hover {
    .InfoTileButton {
      opacity: 1;
    }
  }
`;

type BasicTileImageProps = {
  imageUrl: string;
  name: string;
};

export const BasicTileImage = (props: BasicTileImageProps) => {
  return (
    <TileBasic
      style={{
        position: "relative",
        border:
          props.imageUrl !== "notloaded"
            ? `solid 8px ${Colors.white}`
            : `solid 8px ${Colors.lightPink}`,
      }}
    >
      {props.imageUrl !== "notloaded" && (
        <img
          style={{
            filter: "brightness(100%)",
          }}
          src={props.imageUrl}
          alt={props.name}
        />
      )}
    </TileBasic>
  );
};
