import React, { createContext, useContext, useState } from "react";
export type BADGER_ATTR = {
  trait_type: string;
  value: string;
};

export type BADGER_TYPE = {
  name: string;
  description: string;
  image: string;
  dna: string;
  edition: string;
  date: number;
  attributes: BADGER_ATTR[];
  compiler: string;
  imageUrl: string;
  id: number;
};

export type BABY_BADGER_TYPE = {
  name: string;
  baby_id: number;
  imageUrl: string;
  parent_1: number;
  parent_2: number;
};

export type ATTEMPTED_BUR_BADGER_TYPE = {
  name: string;
  description: string;
  image: string;
  dna: string;
  edition: string;
  date: number;
  attributes: BADGER_ATTR[];
  compiler: string;
  imageUrl: string;
  id: number;
  txID: string;
};

type HeartState = {
  totalHearts: number | null;
  lastBlockChecked: number | null;
};
type INIT_STATE_TYPE = {
  senderAddress?: string;
  authenticated: boolean;
  badgers: BADGER_TYPE[];
  BADGER_0: BADGER_TYPE | null;
  BADGER_1: BADGER_TYPE | null;

  heartInfo: HeartState;
  totalHearts: Number;

  breedBadgers: ATTEMPTED_BUR_BADGER_TYPE[];
  pendingBadgers: ATTEMPTED_BUR_BADGER_TYPE[];

  latestBlock: number | null;

  _TotalHearts: (amount: number) => void;

  UPDATE_BADGER_0: (badger: BADGER_TYPE | null) => void;

  UPDATE_BADGER_1: (badger: BADGER_TYPE | null) => void;
  _senderAddress: (address: string | undefined) => void;
  _authenticated: (authenticated: boolean) => void;
  _badgers: (badgers: BADGER_TYPE[]) => void;
  _breedBadgers: (badgers: ATTEMPTED_BUR_BADGER_TYPE[]) => void;
  _pendingBadgers: (badgers: ATTEMPTED_BUR_BADGER_TYPE[]) => void;
  _latestBlock: (status: number | null) => void;
  _heartInfo: (heartState: HeartState) => void;
};

const INIT_HEART_STATE = {
  totalHearts: null,
  lastBlockChecked: null,
};
const INIT_STATE = {
  senderAddress: undefined,
  authenticated: false,
  badgers: [],
  breedBadgers: [],

  heartInfo: INIT_HEART_STATE,
};

export const StateContext = createContext<INIT_STATE_TYPE>(null!);

const StateLogic = (props: React.PropsWithChildren<{}>) => {
  const [senderAddress, _senderAddress] = useState<string | undefined>(
    INIT_STATE.senderAddress
  );
  const [authenticated, _authenticated] = useState<boolean>(false);

  const [totalHearts, _TotalHearts] = useState<Number>(0);
  const [latestBlock, _latestBlock] = useState<number | null>(null);

  const [heartInfo, _heartInfo] = useState<HeartState>(INIT_HEART_STATE);

  const [BADGER_0, UPDATE_BADGER_0] = useState<BADGER_TYPE | null>(null);
  const [BADGER_1, UPDATE_BADGER_1] = useState<BADGER_TYPE | null>(null);
  const [badgers, _badgers] = useState<BADGER_TYPE[]>([]);

  const [breedBadgers, _breedBadgers] = useState<ATTEMPTED_BUR_BADGER_TYPE[]>(
    []
  );
  const [pendingBadgers, _pendingBadgers] = useState<
    ATTEMPTED_BUR_BADGER_TYPE[]
  >([]);

  let contextValue = {
    senderAddress,
    _senderAddress,

    authenticated,
    _authenticated,

    badgers,
    _badgers,

    BADGER_0,
    UPDATE_BADGER_0,

    UPDATE_BADGER_1,
    BADGER_1,

    totalHearts,
    _TotalHearts,

    breedBadgers,
    _breedBadgers,

    pendingBadgers,
    _pendingBadgers,

    latestBlock,
    _latestBlock,

    heartInfo,
    _heartInfo,
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
};

export default StateLogic;

export const useAppState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
};
