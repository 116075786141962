export const APP_SHADOWS = [
  "0 1px 4px rgba(37, 50, 56, 0.12), 0 1px 3px rgba(37, 50, 56, 0.24)",
  "0 3px 6px rgba(37, 50, 56, 0.16), 0 3px 6px rgba(37, 50, 56, 0.23)",
  "0 10px 20px rgba(37, 50, 56, 0.19), 0 6px 6px rgba(37, 50, 56, 0.23)",
  "0 14px 28px rgba(37, 50, 56, 0.25), 0 10px 10px rgba(37, 50, 56, 0.22)",
  "0 19px 38px rgba(37, 50, 56, 0.30), 0 15px 12px rgba(37, 50, 56, 0.22)",
];
export const APP_COLORS = {
  primary: "#212121",
  secondary: "#fafafa",
  green: "#5fc5b5",
  darkGreen: "#3ba896",
  lightGreen: "#8ddfd1",

  purple: "#212121",
  darkPurple: "#413867",
  gray: "#EBEBEB",
  //all Colors
  black: "#212121",
  lightBlack: "#424242",
  white: "#F7F7F7",
  darkWhite: "#F7F7F7",
  textWhite: "#FFFFFF",
  textBlack: "#212121",
  textLightBlack: "#636363",
  blue: "#6e97ff",

  app: {
    black: "#000000",
    lightPink: "#FFEEF7",
    midPink: "#FFC1E0",
    brightPink: "#FF5FAF",
    burgundy: "#840042",
    white: "#FFFFFF",
  },
};

const Colors = APP_COLORS.app;

export default Colors;
