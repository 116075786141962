import React from "react";
import Loader from "react-loader-spinner";
import Colors from "../const/colors";
import { APP_THEME } from "../const/theme";

const LoaderPlaceHolder = () => {
  return (
    <Loader type="Hearts" color={Colors.brightPink} height={160} width={160} />
  );
};

export default LoaderPlaceHolder;
