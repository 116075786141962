import Colors from "../const/colors";
import styled from "styled-components";
import { BADGER_TYPE, useAppState } from "./state";
import { height, zIndex } from "styled-system";
import {
  ColumnFlex,
  LabelHeading,
  RowFlex,
  SubText,
  TriHeading,
  TriText,
  Text,
} from "./styled";
import traitHeartSelected from "../assets/traitHeartSelected.svg";
import traitHeartUnselected from "../assets/traitHeartUnselected.svg";

export const EmptyWhitePlaceholder = () => {
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        top: "0",
        left: "0",
        right: "0",
        bottom: "2px",
        position: "absolute",
        zIndex: 10,
        height: "30%",
        width: "100%",
        borderRadius: "40px",
      }}
    />
  );
};

interface Rprops {
  children?: React.ReactNode;
}
export const MainTraitSelectionCon = ({ children }: Rprops) => {
  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1400px",
        height: "75vh",
        minHeight: "720px",
        maxHeight: "800px",
        position: "relative",
        margin: "auto",
        borderRadius: "40px",
        bottom: 0,
        marginTop: "10vh",
      }}
    >
      {children}
    </div>
  );
};

export const TraitSelectionConL = ({ children }: Rprops) => {
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        borderRadius: "40px",
        bottom: "0",
        left: "0",
        position: "absolute",
        height: "100%",
        maxWidth: "350px",
        minHeight: "700px",
        width: "25%",
        zIndex: 70,
      }}
    >
      {children}
    </div>
  );
};

export const TraitSelectionConR = ({ children }: Rprops) => {
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        borderRadius: "40px",
        bottom: "0",
        right: "0",
        position: "absolute",
        height: "100%",
        maxWidth: "350px",
        minHeight: "700px",
        width: "25%",
        zIndex: 70,
      }}
    >
      {children}
    </div>
  );
};

export const MiddleSection = ({ children }: Rprops) => {
  return (
    <div
      style={{
        backgroundColor: Colors.midPink,
        position: "absolute",
        left: "0",
        right: "0",
        bottom: "0",
        margin: "auto",
        borderRadius: "20px",
        height: "72%",
        zIndex: 30,
        width: "50%",
      }}
    >
      <div
        style={{
          padding: "20px 10px",
          width: "96%",
          margin: "auto",
          height: "99%",
          borderRadius: "20px",
        }}
      >
        <div
          style={{
            margin: "auto",
            left: 0,
            right: 0,

            width: "100%",
            backgroundColor: Colors.lightPink,
            height: "95%",
            borderRadius: "20px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
type SelectedBadgerImagesProps = {
  badger1: BADGER_TYPE;
  badger2: BADGER_TYPE;
};

export const SelectedBadgerImages = ({
  badger1,
  badger2,
}: SelectedBadgerImagesProps) => {
  return (
    <div
      style={{
        position: "absolute",
        right: "0",
        left: "0",
        top: "-120px",
        zIndex: 50,
      }}
    >
      <RowFlex alignItems={"center"} justifyContent={"center"}>
        <div
          style={{
            margin: "16px",
          }}
        >
          <SelectedBadger badger={badger1} />
        </div>
        <div
          style={{
            margin: "16px",
          }}
        >
          <SelectedBadger badger={badger2} />
        </div>
      </RowFlex>
    </div>
  );
};

export const SelectedBadgerTile = styled.div`
  cursor: pointer;
  overflow: hidden;
  width: 250px;
  height: 250px;
  animate: all ease 0.2s;
  padding: 8px;
  position: relative;
  border: solid 2px ${Colors.brightPink};
  border-radius: 12px;

  img {
    width: 100%;
    transition: transform 500ms ease;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

type uwuProps = {
  top: string;
  bottom: string;
  id: string;
  active: undefined | string;
  handleTraitClicked: (traitId: string) => void;
};
export const BadgerTraitInfoColumn = ({
  id,
  top,
  bottom,
  handleTraitClicked,
  active,
}: uwuProps) => {
  return (
    <RowFlex
      alignItems={"center"}
      justifyContent={"space-between"}
      margin={"8px 5px"}
      padding="0 20px"
    >
      <ColumnFlex>
        <TriText color="black">{top}</TriText>
        <TriText color="black" fontWeight={"800"}>
          {bottom}
        </TriText>
      </ColumnFlex>
      <div onClick={() => handleTraitClicked(id)} style={{ cursor: "pointer" }}>
        <img
          style={{
            filter: "brightness(100%)",
          }}
          src={active ? traitHeartSelected : traitHeartUnselected}
          alt={"Select Trait"}
        />
      </div>
    </RowFlex>
  );
};

type BadgerTraitSelectionProps = {
  badger: BADGER_TYPE;
  activeTraits: string[];
  handleTraitClicked: (traitId: string) => void;
};

export const BadgerTraitSelection = (props: BadgerTraitSelectionProps) => {
  const { badger, activeTraits, handleTraitClicked } = props;

  console.log(" BadgerTraitSelection activeTraits", activeTraits);
  return (
    <ColumnFlex width={"100%"} alignItems={"center"}>
      <LabelHeading style={{ marginTop: "20px" }} padding={"20px 10px"}>
        {`Badger #${props.badger.id}`}
      </LabelHeading>
      <ColumnFlex width={"100%"}>
        {badger.attributes.map((d, i) => {
          const traitId = `${badger.id}-${i + 1}`;
          const active = activeTraits.find((d, i) => {
            return d === traitId;
          });

          return (
            <BadgerTraitInfoColumn
              handleTraitClicked={handleTraitClicked}
              active={active}
              id={traitId}
              top={d.trait_type}
              bottom={d.value}
              key={traitId}
            />
          );
        })}
      </ColumnFlex>
    </ColumnFlex>
  );
};

type SelectedBadgerProps = {
  badger: BADGER_TYPE;
};

export const SelectedBadger = (props: SelectedBadgerProps) => {
  const { badger } = props;

  return (
    <SelectedBadgerTile>
      <img
        style={{
          filter: "brightness(100%)",
        }}
        src={badger.imageUrl}
        alt={badger.name}
      />
    </SelectedBadgerTile>
  );
};

type WrongItem = {
  title: string;
  text: string;
};
export const SomethingWentWrong = (props: WrongItem) => {
  return (
    <div
      style={{
        width: "300px",
        height: "80px",
        backgroundColor: Colors.burgundy,
        borderRadius: "12px",
      }}
    >
      <SubText fontWeight={800} color={"white"}>
        {props.title}
      </SubText>
      <TriText color={"white"}>{props.text}</TriText>
    </div>
  );
};

export const SomethingWentRight = (props: WrongItem) => {
  return (
    <div
      style={{
        width: "300px",
        maxHeight: "80px",
        backgroundColor: Colors.brightPink,
        borderRadius: "12px",
      }}
    >
      <SubText fontWeight={800} color={"white"}>
        {props.title}
      </SubText>
      <TriText color={"white"}>{props.text}</TriText>
    </div>
  );
};
