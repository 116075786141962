import Select from "react-select";

import Colors from "../const/colors";
import { uintCV, callReadOnlyFunction, cvToJSON } from "@stacks/transactions";

import { sentenceCase } from "change-case";

import {
  ColumnFlex,
  LabelHeading,
  RowFlex,
  SubHeading,
  SubText,
  Text,
  TextHeading,
} from "./styled";
import badgerMetaData from "../assets/bitcoin_badgers_metadata.json";
import { ATTEMPTED_BUR_BADGER_TYPE, BADGER_ATTR, BADGER_TYPE } from "./state";
import { useState } from "react";
import { InfoBadger, InfoTileButton, Tile } from "./BreedSelectionViews";
import { CHECK_IF_BREED, CONTRACT_ADDRESS, CONTRACT_NAME } from "../const";
import { marginTop } from "styled-system";
import heartL from "../assets/HeartVectorTile.svg";
import LoaderPlaceHolder from "./Loader";
import { ToastContainer, toast } from "react-toastify";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Verify = () => {
  const [selectedB, _selectedB] = useState<BADGER_TYPE | null>(null);
  const [hasBeenBreed, _hasBeenBreed] = useState<boolean | null>(null);

  const renderOptions = () => {
    const selectOptions = badgerMetaData.map((d, i) => {
      const cleaned = d.name.replace(/\D/g, "");
      const _ID = parseInt(cleaned, 10);

      const attributes = d.attributes.map((d, i) => {
        const attr: BADGER_ATTR = {
          value: d.value,
          trait_type: d.trait_type,
        };
        return attr;
      });

      const _editBadge: BADGER_TYPE = {
        ...d,
        imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmWtLcsypXdSkZcqXJSjozFRvvK3Q3Hp5qBBLyuEBohtJ2/${
          d.name || ""
        }.gif`,
        id: _ID,
        attributes: attributes,
      };

      const thigns = String(_ID).padStart(4, "0");

      return {
        value: thigns,
        label: thigns,
        meta: _editBadge,
      };
    });
    return selectOptions;
  };

  const hanldeChange = (things: any) => {
    console.log("hanldeChange", things);
    _hasBeenBreed(null);
    const badger = things.meta as BADGER_TYPE;
    _selectedB(badger);
    hanldeFetchBadgerStatus(badger);
  };

  const hanldeFetchBadgerStatus = async (badger: BADGER_TYPE) => {
    console.log("is this runnings");
    try {
      const id = badger.name.replace(/\D/g, "");
      const _ID = parseInt(id, 10);

      const statusCheck = await callReadOnlyFunction({
        contractName: CONTRACT_NAME,
        contractAddress: CONTRACT_ADDRESS,
        functionName: CHECK_IF_BREED,
        functionArgs: [uintCV(_ID)],
        senderAddress: "SP1F5T8R6V88PTXCNE30SHNVHN03PEYGCZGJQKRPG",
      });

      const result = cvToJSON(statusCheck);
      if (result && result.success) {
        if (result.value.type === "bool" && !result.value.value) {
          _hasBeenBreed(false);
        } else {
          _hasBeenBreed(true);
        }
      }
    } catch (err) {
      console.log("hanldeFetchBadgerStatus err", err);
    }
  };

  const handleBadgerInfoClick = (badger: BADGER_TYPE) => {
    // if (SmolBadgerInfoRef && SmolBadgerInfoRef.current) {
    //   toast.dismiss(SmolBadgerInfoRef.current);
    // }

    toast(
      ({ closeToast }) => (
        <InfoBadger closeToast={closeToast} badger={badger} />
      ),

      {
        position: "top-right",
        containerId: "verify",
        autoClose: false,
        hideProgressBar: true,
        style: {
          borderRadius: "40px",
          maxHeight: "90vh",
          overflow: "hidden",
          overflowY: "scroll",
        },
        draggable: false,
        closeOnClick: false,
        closeButton: true,
      }
    );
  };

  return (
    <ColumnFlex
      style={{
        height: "80vh",
        width: "100vw",
        position: "relative",
        zIndex: 100,
      }}
      padding={1}
      alignItems={"center"}
    >
      <ToastContainer enableMultiContainer containerId={"verify"} />
      <SubHeading color={Colors.burgundy} fontWeight={800}>
        Verify Tool
      </SubHeading>
      <RowFlex
        justifyContent={"space-between"}
        maxWidth={"1400px"}
        width="100%"
      >
        <ColumnFlex
          width={"527px"}
          padding={2}
          paddingLeft={5}
          paddingRight={5}
          style={{
            borderRadius: "10px",
            textAlign: "center",
          }}
          alignItems={"center"}
          maxHeight="549px"
        >
          <TextHeading color={Colors.brightPink}>
            Search for eligible Badgers
          </TextHeading>
          <div style={{ width: "400px", height: "80px" }}>
            <Select
              onChange={hanldeChange}
              isSearchable
              options={renderOptions()}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
          {hasBeenBreed !== null && selectedB ? (
            <TileImage
              badger={selectedB}
              alreadyBreed={hasBeenBreed}
              handleBadgerInfoClick={handleBadgerInfoClick}
            />
          ) : (
            <div
              style={{
                height: "400px",
                width: "400px",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  border: `solid 2px ${Colors.brightPink}`,
                  padding: "10px",
                  borderRadius: "18px",
                  height: "350px",
                  width: "350px",
                }}
              >
                {selectedB !== null ? (
                  <LoaderPlaceHolder />
                ) : (
                  <img
                    style={{
                      height: "350px",
                      width: "350px",
                      filter: "brightness(100%)",
                    }}
                    src={heartL}
                    alt="Baby Badgers"
                  />
                )}
              </div>
            </div>
          )}
        </ColumnFlex>
        <ColumnFlex
          width={"527px"}
          padding={2}
          paddingLeft={5}
          paddingRight={5}
          style={{
            backgroundColor: Colors.midPink,
            borderRadius: "10px",
            textAlign: "center",
          }}
          alignItems={"center"}
          maxWidth="549px"
        >
          <RowFlex margin={"20px 0"}>
            <LabelHeading
              style={{
                marginRight: "10px",
              }}
              color={Colors.burgundy}
            >
              Dating Advice -
            </LabelHeading>
            <LabelHeading color={Colors.brightPink}>Caution</LabelHeading>
          </RowFlex>
          <SubText margin="10px" textAlign={"left"}>
            You can use this tool to lookup whether Badgers have already paired
            for breeding
          </SubText>
          <SubText
            textAlign={"left"}
            color={Colors.burgundy}
            fontWeight={800}
            margin="10px"
          >
            This will only tell you if a badgers breeding has been committed to
            an anchor block.
          </SubText>
          <SubText margin="10px" textAlign={"left"}>
            We can only verify at the moment you check using the tool.
          </SubText>
          <SubText margin="10px" textAlign={"left"}>
            Pending transactions in microblocks will not appear.
          </SubText>
          <SubText margin="10px 0 30px 0" textAlign={"left"}>
            Stay safe out there, and spread the love!
          </SubText>
        </ColumnFlex>
      </RowFlex>
    </ColumnFlex>
  );
};

export default Verify;

type TileImageProps = {
  badger: BADGER_TYPE;

  alreadyBreed: boolean | null;

  handleBadgerInfoClick: (badger: BADGER_TYPE) => void;
};
export const TileImage = (props: TileImageProps) => {
  const {
    badger,

    alreadyBreed,
  } = props;

  const hanldeUpdatingInfobadger = () => {
    props.handleBadgerInfoClick(badger);
  };
  const handlePreventingCLick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    hanldeUpdatingInfobadger();
  };

  return (
    <Tile
      style={{
        position: "relative",
        border: `solid 8px ${Colors.brightPink}`,
      }}
    >
      {alreadyBreed ? (
        <div
          style={{
            padding: "0px 6px",
            height: "50px",
            backgroundColor: "#C1FFCB",
            borderRadius: "5px",
            position: "absolute",
            top: "20px",
            right: "0",
            left: "0",
            margin: "auto",
            zIndex: 2,
            maxWidth: "230px",
          }}
        >
          <Text fontWeight={600} color={"#008416"} textAlign={"center"}>
            Eligible Badger...
          </Text>
        </div>
      ) : (
        <div
          style={{
            padding: "0px 6px",
            height: "50px",
            backgroundColor: "#FF5F5F",
            borderRadius: "5px",
            position: "absolute",
            top: "20px",
            right: "0",
            left: "0",
            margin: "auto",
            zIndex: 1,
            maxWidth: "230px",
          }}
        >
          <Text fontWeight={600} color={"#FFFFFF"} textAlign={"center"}>
            This ones taken...
          </Text>
        </div>
      )}

      <InfoTileButton
        onClick={handlePreventingCLick}
        className="InfoTileButton"
        style={{
          transition: "all ease .2s",
        }}
      >
        <LabelHeading textAlign={"center"} color="white">
          i
        </LabelHeading>
      </InfoTileButton>
      <img
        style={{
          filter: "brightness(100%)",
        }}
        src={props.badger.imageUrl}
        alt={props.badger.name}
      />
    </Tile>
  );
};
