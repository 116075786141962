import { height } from "styled-system";
import Colors from "../const/colors";
import { useAppState } from "./state";
import {
  ColumnFlex,
  LabelHeading,
  RowFlex,
  SubText,
  Text,
  TriText,
} from "./styled";
import heartL from "../assets/mhearts.svg";

import {
  FungibleConditionCode,
  AnchorMode,
  makeStandardSTXPostCondition,
  falseCV,
  PostCondition,
} from "@stacks/transactions";
import { openContractCall } from "@stacks/connect";
import {
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
  NETWORK,
  PUR_EIGHT_HEARTS,
  PUR_FOUR_HEARTS,
  PUR_TWO_HEARTS,
} from "../const";
import { toast } from "react-toastify";

import { useEffect } from "react";
import { SomethingWentRight, SomethingWentWrong } from "./TraitSelectionViews";

const BuyHearts = () => {
  const {
    authenticated,
    _senderAddress,
    _authenticated,
    _badgers,
    senderAddress,
    totalHearts,
    latestBlock,
  } = useAppState();

  const handlePurchaseHearts = async (totalHearts: number) => {
    try {
      if (senderAddress) {
        const postConditionCode = FungibleConditionCode.GreaterEqual;
        //const
        let postConditionAmount = 5000000n;
        let funcName = PUR_TWO_HEARTS;
        if (totalHearts === 2) {
          postConditionAmount = 10000000n;
        } else if (totalHearts === 4) {
          postConditionAmount = 20000000n;
          funcName = PUR_FOUR_HEARTS;
        } else if (totalHearts === 8) {
          postConditionAmount = 40000000n;
          funcName = PUR_EIGHT_HEARTS;
        }

        const postConditions: PostCondition[] = [
          makeStandardSTXPostCondition(
            senderAddress,
            postConditionCode,
            postConditionAmount
          ),
        ];

        const txOptions = {
          contractAddress: CONTRACT_ADDRESS,
          contractName: CONTRACT_NAME,
          functionName: funcName,
          functionArgs: [],
          senderKey: senderAddress,
          validateWithAbi: true,
          network: NETWORK,
          postConditions: postConditions,
          anchorMode: AnchorMode.Any,
          onFinish: (data: any) => {
            console.log("we did it", data);
            toast(
              ({ closeToast }) => (
                <SomethingWentRight
                  title={`Purchased ${totalHearts} Heart/s`}
                  text={`Please wait for anchor block ${
                    latestBlock ? latestBlock + 1 : ""
                  } to Confirm`}
                />
              ),

              {
                autoClose: false,
                position: "top-right",
                containerId: "selection",
                //  autoClose: true,
                hideProgressBar: false,
                toastId: "testshitufk",
                style: {
                  backgroundColor: Colors.brightPink,
                  borderRadius: "12px",
                  cursor: "auto",
                },
                draggable: false,
                closeOnClick: true,
                closeButton: true,
              }
            );
          },
          onCancel: () => {
            console.log("err");
            toast(
              ({ closeToast }) => (
                <SomethingWentWrong
                  title="Something Went Wrong"
                  text="Please check if everything right on your side"
                />
              ),

              {
                position: "top-right",
                containerId: "selection",
                //  autoClose: true,
                hideProgressBar: false,
                toastId: "testshitufk",
                style: {
                  backgroundColor: Colors.burgundy,
                  borderRadius: "12px",
                  cursor: "auto",
                },
                draggable: false,
                closeOnClick: true,
                closeButton: true,
              }
            );
          },
        };

        console.log("txtOption", txOptions);
        const transaction = await openContractCall(txOptions);
        console.log("transation", transaction);
      }
    } catch (err) {
      console.log("handlePurchaseHearts err", err);
    }
  };
  return (
    <div
      style={{
        backgroundColor: Colors.midPink,
        width: "100%",
        borderRadius: "12px",
        padding: "20px 0 0 0",
      }}
    >
      <ColumnFlex alignItems={"center"} padding={2}>
        <RowFlex alignItems={"center"}>
          <div
            style={{
              position: "relative",
              height: "60px",
              width: "60px",
              marginRight: "10px",
            }}
          >
            <img
              style={{
                height: "60px",
                width: "60px",
                filter: "brightness(100%)",
              }}
              src={heartL}
              alt="Baby Badgers"
            />
            <LabelHeading
              style={{
                position: "absolute",
                right: 0,
                top: "15px",
                margin: "auto",
                bottom: 0,
                left: 0,
                textAlign: "center",
              }}
              color={Colors.burgundy}
            >
              {`${totalHearts}`}
            </LabelHeading>
          </div>
          <LabelHeading fontWeight={800} color="white">
            Hearts Available
          </LabelHeading>
        </RowFlex>

        <div
          style={{
            width: "450px",
            height: "2px",
            backgroundColor: Colors.brightPink,
            margin: "10px 0",
          }}
        />
        <TriText fontWeight={800} color={Colors.burgundy}>
          Buying Hearts will bind them to this wallet
        </TriText>
        {senderAddress && (
          <div
            style={{
              padding: "15px 15px",
              backgroundColor: Colors.brightPink,
              borderRadius: "85px",
              margin: "10px 0",
              cursor: "pointer",
            }}
          >
            <RowFlex alignItems={"center"}>
              <div
                style={{
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "green",
                  marginRight: "5px",
                }}
              />
              <TriText
                color={Colors.burgundy}
                fontWeight={800}
              >{`${senderAddress.substring(0, 4)}...${senderAddress.substring(
                senderAddress.length - 4
              )}`}</TriText>
            </RowFlex>
          </div>
        )}
        <TriText
          style={{ maxWidth: "300px" }}
          textAlign={"center"}
          color={Colors.burgundy}
          margin={"0 0 20px 0"}
        >
          You’ll still be able to use them for other badger pairs in this wallet
        </TriText>
        <Text fontWeight={600} color={Colors.brightPink}>
          Each Heart...
        </Text>
        <RowFlex>
          <SubText fontWeight={800} color={Colors.burgundy}>
            increases the chance
          </SubText>
          <SubText margin="0 0 0 6px" color={Colors.burgundy}>
            your baby gets the
          </SubText>
        </RowFlex>
        <RowFlex>
          <SubText color={Colors.burgundy}> seleted trait</SubText>
          <SubText margin="0 6px" fontWeight={800} color={Colors.burgundy}>
            by 25%
          </SubText>
          <SubText color={Colors.burgundy}> while spreading the love</SubText>
        </RowFlex>

        <div style={{ marginTop: "20px" }} />
        <div
          style={{
            padding: "10px 20px",
            backgroundColor: Colors.brightPink,
            borderRadius: "85px",
            margin: "10px 0px",
            width: "340px",
            cursor: "pointer",
          }}
          onClick={() => handlePurchaseHearts(2)}
        >
          <RowFlex justifyContent={"space-between"}>
            <RowFlex>
              <Text color="white">Buy</Text>
              <Text margin="0 8px" color={Colors.burgundy} fontWeight={800}>
                +2
              </Text>
              <Text color="white">Heart</Text>
            </RowFlex>

            <Text color={Colors.burgundy} fontWeight={600}>
              10stx
            </Text>
          </RowFlex>
        </div>
        <div
          style={{
            padding: "10px 20px",
            backgroundColor: Colors.brightPink,
            borderRadius: "85px",
            margin: "10px 0px",
            width: "340px",
            cursor: "pointer",
          }}
          onClick={() => handlePurchaseHearts(4)}
        >
          <RowFlex justifyContent={"space-between"}>
            <RowFlex>
              <Text color="white">Buy</Text>
              <Text margin="0 8px" color={Colors.burgundy} fontWeight={800}>
                +4
              </Text>
              <Text color="white">Heart</Text>
            </RowFlex>

            <Text color={Colors.burgundy} fontWeight={600}>
              20stx
            </Text>
          </RowFlex>
        </div>
        <div
          style={{
            padding: "10px 20px",
            backgroundColor: Colors.brightPink,
            borderRadius: "85px",
            margin: "10px 0px",
            width: "340px",
            cursor: "pointer",
          }}
          onClick={() => handlePurchaseHearts(8)}
        >
          <RowFlex justifyContent={"space-between"}>
            <RowFlex>
              <Text color="white">Buy</Text>
              <Text margin="0 8px" color={Colors.burgundy} fontWeight={800}>
                +8
              </Text>
              <Text color="white">Heart</Text>
            </RowFlex>

            <Text color={Colors.burgundy} fontWeight={600}>
              40stx
            </Text>
          </RowFlex>
        </div>
        <div style={{ marginBottom: "20px" }} />
      </ColumnFlex>
    </div>
  );
};

export default BuyHearts;
