import styled from "styled-components";
import {
  Button,
  ColumnFlex,
  RowFlex,
  SubText,
  Text,
  TriHeading,
  TriText,
} from "./styled";
import STXLogo from "../assets/STX-Logo.png";

import { useConnect } from "@stacks/connect-react";
import { useAppState } from "./state";

import Cupid from "../assets/Cupid.png";

import Colors from "../const/colors";
import BuyHearts from "./BuyHearts";

import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

import { io } from "socket.io-client";
import * as stacks from "@stacks/blockchain-api-client";
import ConnectWallet, { blocksAPI } from "./ConnectWallet";
import { Link, Route, useLocation } from "wouter";
import { SomethingWentRight } from "./TraitSelectionViews";
import {
  connectWebSocketClient,
  StacksApiWebSocketClient,
} from "@stacks/blockchain-api-client";

export const COLORS = {
  Background: "#6e97ff",
  Primary: "#060713",
  Secondary: "#ffffff",
  Tertiary: "#252e46",
  four: "#2ce6ff",
  red: "#ff7c7e",
};

export const ButtonCon = styled.div`
  cursor: pointer;
  transition: all ease 0.3s;

  .button-2:hover {
    background-color: rgba(265, 95, 175, 0.65);
  }
  .button-2 {
    background-color: ${({ color }) => color};
  }
`;

const StyledToast = styled(ToastContainer)`
  &&&.wild {
    width: 520px;
  }
  &&&.nild {
    max-height; 800px;
  }
`;

const LogoWrapper = styled.div`
  display: inline-block;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(37, 46, 70, 0.24);
`;

export const HiroWallet = () => {
  return (
    <>
      <LogoWrapper>
        <img
          style={{
            width: "42px",
            verticalAlign: "midddle",
            maxWidth: "100%",
            display: "inline-block",
          }}
          src={STXLogo}
          alt="banner"
        />
      </LogoWrapper>
    </>
  );
};

const Navigation = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #6e97ff;

  height: 10%;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: transparent;
  @media (min-width: 768px) {
    margin: 20px 0px;
    padding-right: 100px;
    padding-left: 100px;
  }
`;

export const NavigationHeader = () => {
  const { doOpenAuth } = useConnect();
  const {
    authenticated,
    _senderAddress,
    _authenticated,
    _badgers,
    senderAddress,
    latestBlock,
    UPDATE_BADGER_0,
    UPDATE_BADGER_1,
    _TotalHearts,
    _breedBadgers,
    _pendingBadgers,
  } = useAppState();

  const [location, setLocation] = useLocation();

  const initAuth = () => {
    doOpenAuth();
  };

  const handleLogout = () => {
    localStorage.removeItem("peak");
    localStorage.removeItem("hearts");
    localStorage.removeItem("pending");
    localStorage.removeItem("breed");
    _senderAddress(undefined);
    _authenticated(false);
    _pendingBadgers([]);
    _badgers([]);
    UPDATE_BADGER_0(null);
    UPDATE_BADGER_1(null);
    _TotalHearts(0);
    _breedBadgers([]);
  };
  const handleViewTx = () => {
    setLocation("/");
  };

  const handleHeartButtonClick = () => {
    toast(
      ({ closeToast }) => <BuyHearts />,

      {
        position: "top-center",
        containerId: "nav",
        autoClose: false,
        hideProgressBar: true,
        toastId: "testshitufk",
        style: {
          backgroundColor: "transparent",

          boxShadow: "none",
          padding: "0",
          maxHeight: "90vh",
          overflow: "hidden",
          cursor: "auto",
        },
        draggable: false,
        closeOnClick: false,
        closeButton: true,
      }
    );
  };

  return (
    <>
      <StyledToast
        containerId={"nav"}
        toastClassName={"nild"}
        className={"wild"}
        enableMultiContainer
      />
      <Navigation>
        <Text
          fontFamily="Poppins,sans-serif;"
          color={Colors.burgundy}
          fontWeight={700}
          fontSize="20px"
          letterSpacing="1.5px"
          onClick={() => handleViewTx()}
          style={{ cursor: "pointer" }}
        >
          Bitcoin Badgers
        </Text>
        <RowFlex alignItems={"center"}>
          {latestBlock && (
            <RowFlex marginRight={"20px"} alignItems={"center"}>
              <div
                style={{
                  borderRadius: "2px",
                  width: "12px",
                  height: "12px",
                  backgroundColor: "green",
                  marginRight: "5px",
                }}
              />
              <TriText
                color={Colors.burgundy}
                fontWeight={500}
                margin="0 5px 0 2px"
              >
                Height
              </TriText>
              <TriText
                color={Colors.burgundy}
                fontWeight={800}
              >{`${latestBlock}`}</TriText>
            </RowFlex>
          )}
          {/*
          !authenticated && (
            <ButtonCon
              className="button-container stx"
              onClick={() => initAuth()}
              color={COLORS.four}
              style={{
                display: "inline-block",
                paddingRight: "3px",
                justifyContent: "center",
                paddingBottom: "6px",
                borderRadius: "100px",
                backgroundColor: Colors.brightPink,
                cursor: "pointer",
                minWidth: "180px",
              }}
            >
              <div
                className="button-2 w-button w--current"
                style={{
                  padding: "10px 15px",
                  borderRadius: "1000px",
                  margin: "auto",
                  fontFamily: "Poppins, sans-seri",
                  border: 0,
                  color: "white",
                  fontSize: "30px",
                  fontWeight: 700,
                  textAlign: "center",
                  backgroundColor: "rgba(6, 7, 19, 0.65)",
                }}
              >
                <SubText fontFamily="Poppins,sans-serif">
                  Connect Wallet
                </SubText>
              </div>
            </ButtonCon>
          )
                */}
          {senderAddress && (
            <>
              <RowFlex alignItems={"center"}>
                <div
                  style={{
                    borderRadius: "50%",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "green",
                    marginRight: "5px",
                  }}
                />
                <TriText
                  color={Colors.burgundy}
                  fontWeight={800}
                >{`${senderAddress.substring(0, 4)}...${senderAddress.substring(
                  senderAddress.length - 4
                )}`}</TriText>
              </RowFlex>
              {/* <div
                onClick={() => handleHeartButtonClick()}
                style={{
                  padding: "5px 20px",
                  backgroundColor: Colors.burgundy,
                  borderRadius: "85px",
                  margin: "0px 10px 0 20px",
                  cursor: "pointer",
                }}
              >
                <Text color="white">Buy Hearts</Text>
              </div> */}
            </>
          )}

          {authenticated && (
            <ButtonCon
              className="button-container stx"
              onClick={() => handleLogout()}
              color={COLORS.red}
              style={{
                display: "inline-block",
                paddingRight: "3px",
                justifyContent: "center",
                paddingBottom: "6px",
                borderRadius: "100px",
                backgroundColor: Colors.brightPink,
                cursor: "pointer",
                minWidth: "180px",
                marginLeft: "20px",
              }}
            >
              <div
                style={{
                  padding: "10px 15px",
                  borderRadius: "1000px",
                  margin: "auto",
                  fontFamily: "Poppins, sans-seri",
                  border: 0,
                  color: "white",
                  fontSize: "30px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                <SubText fontFamily="Poppins,sans-serif;">Log Out</SubText>
              </div>
            </ButtonCon>
          )}
          {
            <div
              style={{
                borderRadius: "56px",
                padding: "10px 15px",
                border: `solid 2px ${Colors.brightPink}`,
                cursor: "pointer",
              }}
              onClick={() => setLocation("/finder")}
            >
              <SubText
                textAlign={"center"}
                color={Colors.brightPink}
                fontWeight={400}
              >
                Finder Tool
              </SubText>
            </div>
          }
        </RowFlex>
      </Navigation>
    </>
  );
};

export const BadgerFooter = () => {
  return (
    <ColumnFlex
      marginBottom="00px"
      height="20%"
      alignItems="center"
      justifyContent="flex-end"
    >
      <img width={"166px"} src={Cupid} />
    </ColumnFlex>
  );
};

export const BadgerBabyButton = () => {
  return (
    <RowFlex style={{ position: "relative" }}>
      <img
        style={{ borderRadius: "50%", zIndex: 200 }}
        width="100px"
        height="100px"
        src={Cupid}
        alt="Baby Badger"
      />
      <div style={{ width: "80px" }} />
      <div
        style={{
          width: "100px",
          height: "100px",
          backgroundColor: "black",
          borderRadius: "50%",
          color: "white",
          textAlign: "center",
          position: "absolute",
          right: "10px",
        }}
      >
        <ColumnFlex
          height={"100%"}
          alignItems={"center"}
          justifyContent="center"
        >
          <TriHeading color="white" style={{ margin: "auto" }}>
            ?
          </TriHeading>
        </ColumnFlex>
      </div>
    </RowFlex>
  );
};

const socketUrl = "https://stacks-node-api.mainnet.stacks.co/";
const socket = io(socketUrl, {
  query: {
    subscriptions: Array.from(new Set(["block"])).join(","),
  },
  transports: ["websocket"],
});

const SubComp = () => {
  const { _latestBlock } = useAppState();
  useEffect(() => {
    //handleSubscibeWeb();
    //fetchLatestBlock();
  }, []);

  const handleSubscibeWeb = async () => {
    const sc = new stacks.StacksApiSocketClient(socket);
    // //const wtf = new stacks.StacksApiWebSocketClient(socket);

    // const wsUrl = new URL('ws://stacks-node-api.mainnet.stacks.co/');
    // wsUrl.protocol = 'ws:';
    // let client: null | StacksApiWebSocketClient;

    // async function run() {
    //   client = await connectWebSocketClient(wsUrl.toString());

    // }

    //void run();

    sc.socket.on("connect", () => {
      const engine = socket.io.engine;

      engine.on("packet", ({ type, data }) => {
        // called for each packet received
        console.log("type", type);
        if (type !== "ping") {
          // console.log("data", data);
          // console.log("data thigns", data[1]);
          // console.log("data[1].height", data[1].height);
          // console.log(JSON.parse(data));

          // const height = data[1].height || null;
          // console.log("height", height);

          //_latestBlock(height);
          fetchLatestBlock();
        }
      });

      engine.on("close", (reason) => {
        // called when the underlying connection is closed
      });
    });
  };

  const fetchLatestBlock = async () => {
    try {
      const blockList = await blocksAPI.getBlockList({});
      console.log("blockList", blockList);
      if (blockList.results.length > 0) {
        _latestBlock(blockList.results[0].height);
        toast(
          ({ closeToast }) => (
            <SomethingWentRight
              title={`Current Block ${blockList.results[0].height}`}
              text={""}
            />
          ),

          {
            position: "top-right",
            containerId: "selection",
            //  autoClose: true,
            hideProgressBar: false,
            toastId: "",
            style: {
              backgroundColor: Colors.brightPink,
              borderRadius: "12px",
              cursor: "auto",
            },
            draggable: false,
            closeOnClick: true,
            closeButton: true,
          }
        );
      }
    } catch (err) {}
  };

  return null;
};

export const BabyApp = () => {
  return (
    <div>
      <SubComp />
      <ConnectWallet />
    </div>
  );
};

export const ValButton = () => {
  const { doOpenAuth } = useConnect();
  return (
    <Button
      height={"70px"}
      width={"300px"}
      backgroundColor={"#FF5FAF"}
      borderRadius={"5px"}
      onClick={() => doOpenAuth()}
    >
      <ColumnFlex height={"100%"} alignItems={"center"}>
        <Text textAlign={"center"} margin={"auto"} color="white">
          Find Connection
        </Text>
      </ColumnFlex>
    </Button>
  );
};
