import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";

import "../css/App.css";

import { APP_THEME } from "../const/theme";
import Colors from "../const/colors";

import StateLogic from "./state";
import { BabyApp } from "./Landing";
import Verify from "./Verify";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    height: 100%;
  }
  body {
    display: block;
    height: 100%;
    background-color: ${Colors.lightPink};


    margin: 0;
    min-height: 100%;
   
    font-family: Arial,sans-serif;
   
  }
  #root {
    display: block;
    height: 100%;
    background-color: ${Colors.lightPink};


    margin: 0;
    min-height: 100%;
   
    font-family: Arial,sans-serif;
  }
`;

function App() {
  return (
    <ThemeProvider theme={APP_THEME}>
      <StateLogic>
        <GlobalStyle />

        <BabyApp />
      </StateLogic>
    </ThemeProvider>
  );
}

export default App;
