import { useConnect, Connect } from "@stacks/connect-react";
import {
  AccountsApi,
  AddressNftListResponse,
  TransactionsApi,
  SmartContractsApi,
  BlocksApi,
  AddressTransactionsListResponse,
} from "@stacks/blockchain-api-client";

import {
  standardPrincipalCV,
  callReadOnlyFunction,
  cvToJSON,
} from "@stacks/transactions";

import { BADGER_ATTR, BADGER_TYPE, useAppState } from "./state";
import { Link, Route } from "wouter";

import {
  Button,
  ColumnFlex,
  LabelHeading,
  Port,
  RowFlex,
  Text,
  TextHeading,
} from "./styled";

import badgerMetaData from "../assets/bitcoin_badgers_metadata.json";
import bbyBadgerTitle from "../assets/bbyBadgerTitle.svg";
import heartL from "../assets/heartL.svg";
import cupid from "../assets/Cupid.png";

import {
  BadgerFooter,
  HiroWallet,
  NavigationHeader,
  BadgerBabyButton,
  ValButton,
} from "./Landing";
import { useEffect, useState } from "react";
import {
  BADGER_ADDRESS_V2,
  BREED_BABY_V1,
  CONFIG_BC_S,
  STACKS_USER_SESSION,
} from "../utils";
import BreedSelection from "./BreedSelection";

import Colors from "../const/colors";

import { CONTRACT_ADDRESS, CONTRACT_NAME, HEART_BALANCE } from "../const";
import Verify from "./Verify";
import { colors } from "react-select/dist/declarations/src/theme";
import FamilySearch from "./FamilySearch";

const accountsApi = new AccountsApi(CONFIG_BC_S);
export const transactionsAPI = new TransactionsApi(CONFIG_BC_S);
export const smartContractAPI = new SmartContractsApi(CONFIG_BC_S);
export const blocksAPI = new BlocksApi(CONFIG_BC_S);

const ConnectWallet = () => {
  const {
    authenticated,
    _authenticated,
    _senderAddress,
    _badgers,
    _TotalHearts,
    _pendingBadgers,
    _breedBadgers,
    latestBlock,
    heartInfo,
    _heartInfo,
  } = useAppState();
  const [loading, _Loading] = useState<boolean>(false);

  // Should Run on first render and whenever lastestblock updates
  useEffect(() => {
    const senderAddy = localStorage.getItem("peak");
    if (senderAddy) handleFetchingHearts(senderAddy);
  }, []);

  useEffect(() => {
    fetchInitLocalStorage();
  }, [latestBlock]);

  const fetchInitLocalStorage = () => {
    const senderAddy = localStorage.getItem("peak");
    const totalHearts = localStorage.getItem("hearts");
    const heartState = localStorage.getItem("heartState");

    const pendingBadgers = localStorage.getItem("pending");
    const breedBadgers = localStorage.getItem("breed");

    if (senderAddy) {
      //fetchBadgers(senderAddy);
      //handleFetchingAllTransactions();
      _authenticated(true);
      _senderAddress(senderAddy);

      // Hearts
      if (totalHearts) {
        const _cHearts = parseInt(totalHearts, 10);
        if (totalHearts) _TotalHearts(_cHearts);
      }
      // Already Breed
      if (breedBadgers) {
        const parsedBreedBadgers = JSON.parse(breedBadgers);
        _breedBadgers(parsedBreedBadgers);
      }
      // Waiting Fail or Success
      if (pendingBadgers) {
        console.log("pendingBadgers", pendingBadgers);
        const parsedPending = JSON.parse(pendingBadgers);

        _pendingBadgers(parsedPending);
      }
      if (heartState) {
        const parseHeartInfo = JSON.parse(heartState);
        _heartInfo(parseHeartInfo);
      }
    }
  };

  const handleFetchingHearts = async (senderAddy: string) => {
    try {
      const otherThing = await callReadOnlyFunction({
        contractName: CONTRACT_NAME,
        contractAddress: CONTRACT_ADDRESS,
        functionName: HEART_BALANCE,
        functionArgs: [standardPrincipalCV(senderAddy)],
        senderAddress: senderAddy,
      });

      const result = cvToJSON(otherThing);

      if (result && result.success) {
        if (result.value.value["first-time"].value) {
          const totalHearts = result.value.value["purchased-count"].value;
          const intThing = parseInt(totalHearts, 10) + 1;

          localStorage.setItem("hearts", `${intThing}`);
          _TotalHearts(intThing);
        } else {
          const totalHearts = result.value.value["purchased-count"].value;
          const intThing = parseInt(totalHearts, 10);

          localStorage.setItem("hearts", totalHearts);
          _TotalHearts(intThing);
        }
      }
    } catch (err) {
      console.log("handleFetchinghearts", err);
    }
  };

  const handleFetchingAllTransactions = async () => {
    try {
      let totalFetchedTransaction = 0;
      const accountTransaction = (await accountsApi.getAccountTransactions({
        principal: "SP27F9EJH20K3GT6GHZG0RD08REZKY2TDMD6D9M2Z.baby-badgers",
        offset: 0,
        limit: 50,
      })) as AddressTransactionsListResponse;
      console.log("accountTransaction", accountTransaction);
      totalFetchedTransaction = 50;

      const totalTrans = [...accountTransaction.results];
      const allTransAmount = accountTransaction.total;

      while (totalFetchedTransaction < allTransAmount) {
        const moreAccountTransactions =
          (await accountsApi.getAccountTransactions({
            principal: "SP27F9EJH20K3GT6GHZG0RD08REZKY2TDMD6D9M2Z.baby-badgers",
            offset: totalFetchedTransaction,
            limit: 50,
          })) as AddressTransactionsListResponse;

        moreAccountTransactions.results.forEach((d, i) => {
          totalTrans.push(d);
        });

        totalFetchedTransaction =
          totalFetchedTransaction + moreAccountTransactions.results.length;
      }

      console.log("totalTrans", totalTrans);

      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(totalTrans));
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      if (dlAnchorElem) {
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", "scene.json");
        dlAnchorElem.click();
      }
    } catch (err) {
      console.log("more request yay");
    }
  };
  const fetchBadgers = async (senderAddress: string) => {
    try {
      _Loading(true);

      let totalFetchedNFTevents = 0;
      const accountNFts = (await accountsApi.getAccountNft({
        principal: senderAddress,
        offset: 0,
        limit: 50,
      })) as AddressNftListResponse;

      totalFetchedNFTevents = 50;
      const badgersThings = [...accountNFts.nft_events];
      const totalNFTEvents = accountNFts.total;

      if (totalNFTEvents >= 50) {
        while (totalFetchedNFTevents < totalNFTEvents) {
          const moreFetched = (await accountsApi.getAccountNft({
            principal: senderAddress,
            offset: totalFetchedNFTevents,
            limit: 50,
          })) as AddressNftListResponse;

          moreFetched.nft_events.forEach((d, i) => {
            badgersThings.push(d);
          });
          totalFetchedNFTevents =
            totalFetchedNFTevents + moreFetched.nft_events.length;
        }
      }

      const badgers = badgersThings.filter((d, i) => {
        return d.asset_identifier === BADGER_ADDRESS_V2;
      });

      const breedBadgers = badgersThings.filter((d, i) => {
        return d.asset_identifier === BREED_BABY_V1;
      });

      const ownedBadgerInfo: BADGER_TYPE[] = [];

      for (const badger of badgers) {
        const id = badger.value.repr.replace(/\D/g, "");
        const _ID = parseInt(id, 10);

        const badge = badgerMetaData.find((d, i) => {
          return d.name === `bitcoin_badger_${_ID}`;
        });

        if (badge) {
          const attributes = badge.attributes.map((d, i) => {
            const attr: BADGER_ATTR = {
              value: d.value,
              trait_type: d.trait_type,
            };
            return attr;
          });

          const _editBadge: BADGER_TYPE = {
            ...badge,
            imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmWtLcsypXdSkZcqXJSjozFRvvK3Q3Hp5qBBLyuEBohtJ2/${
              badge.name || ""
            }.gif`,
            id: _ID,
            attributes: attributes,
          };
          ownedBadgerInfo.push(_editBadge);
        }
      }

      _senderAddress(senderAddress);
      _badgers(ownedBadgerInfo);
      _Loading(false);
    } catch (err) {
      console.log("noooo", err);
      _Loading(false);
    }
  };
  const AUTH_OPTIONS = {
    appDetails: {
      name: "Bitcoin Badgers",
      icon: "https://cdn.discordapp.com/attachments/894667598799724575/942497324855357501/Cupid2x.png",
    },
    redirectTo: "/",
    onFinish: async () => {
      try {
        _Loading(true);
        let userData = STACKS_USER_SESSION.loadUserData();

        const senderAddress = userData.profile.stxAddress.mainnet;
        //fetchBadgers(senderAddress);

        _authenticated(true);
        _senderAddress(senderAddress);
        //handleFetchingHearts(senderAddress);
        _Loading(false);

        localStorage.setItem("peak", senderAddress);
      } catch (err) {
        console.log("noooo", err);
        _Loading(false);
      }
    },
    userSession: STACKS_USER_SESSION,
  };

  if (loading) {
    return (
      <ColumnFlex height="70%" alignItems="center" justifyContent="center">
        <HiroWallet />
        <Port
          textAlign="center"
          color={Colors.burgundy}
          className="loading"
          marginBottom="20px"
          style={{ color: Colors.burgundy }}
        >
          It was Love at first sight...
        </Port>
        <div style={{ marginBottom: "20px" }}>
          <img width={"400px"} height="400px" src={cupid} alt="babyLoading" />
        </div>
      </ColumnFlex>
    );
  }

  return (
    <Connect authOptions={AUTH_OPTIONS}>
      {/* <a id="downloadAnchorElem" /> */}
      <NavigationHeader />
      <Route path="/finder">
        <FamilySearch />
      </Route>
      <Route path="/verify">
        <Verify />
      </Route>
      <Route path="/">
        <img
          style={{
            width: "90vw",
            maxWidth: "740px",
            verticalAlign: "midddle",
            margin: "auto",
            position: "fixed",
            zIndex: 10,
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          }}
          src={heartL}
          alt="Baby Badgers"
        />

        <ColumnFlex
          height="70%"
          alignItems="center"
          justifyContent="center"
          style={{
            position: "relative",
            zIndex: 120,
          }}
        >
          <img
            style={{
              width: "90vw",
              maxWidth: "800px",
              verticalAlign: "midddle",
            }}
            src={bbyBadgerTitle}
            alt="Baby Badgers"
          />
          <RowFlex
            maxWidth={"760px"}
            alignContent={"center"}
            justifyItems={"c"}
            marginBottom={"40px;"}
          >
            <LabelHeading
              style={{
                marginRight: "16px",
              }}
              fontWeight={400}
              fontFamily="Poppins, sans-seri"
            >
              adorable cubs
            </LabelHeading>
            <LabelHeading
              color={"#FF5FAF"}
              fontWeight={400}
              fontFamily="Poppins, sans-seri"
            >
              born out of love
            </LabelHeading>
          </RowFlex>
          <div>
            <Text textAlign={"center"} margin={"auto"} color={"black"}>
              Breeding is closed
            </Text>
            <Text textAlign={"center"} margin={"auto"} color="black">
              thanks for all the love
            </Text>
            {/* <ValButton /> */}
          </div>
        </ColumnFlex>
        <BadgerFooter />
      </Route>
    </Connect>
  );
};
export default ConnectWallet;
