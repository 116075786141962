import Select from "react-select";

import Colors from "../const/colors";
import { uintCV, callReadOnlyFunction, cvToJSON } from "@stacks/transactions";

import { sentenceCase } from "change-case";

import {
  ColumnFlex,
  LabelHeading,
  RowFlex,
  SubHeading,
  SubText,
  Text,
  TextHeading,
  TriHeading,
} from "./styled";
import badgerMetaData from "../assets/bitcoin_badgers_metadata.json";

import babyBadgerMetadata from "../assets/baby_badgers_meta.json";
import babyLeftHeart from "../assets/babyLeftHeart.svg";
import babyRightHeart from "../assets/babyRightHeart.svg";
import recBaby from "../assets/recBaby.svg";

import {
  ATTEMPTED_BUR_BADGER_TYPE,
  BABY_BADGER_TYPE,
  BADGER_ATTR,
  BADGER_TYPE,
} from "./state";
import { useEffect, useState } from "react";
import {
  BasicTileImage,
  InfoBadger,
  InfoTileButton,
  Tile,
} from "./BreedSelectionViews";
import { CHECK_IF_BREED, CONTRACT_ADDRESS, CONTRACT_NAME } from "../const";
import { marginTop, width } from "styled-system";
import heartL from "../assets/HeartVectorTile.svg";
import LoaderPlaceHolder from "./Loader";
import { ToastContainer, toast } from "react-toastify";
import ScratchOff from "./scratch";
import actual from "./actual";
import { Size, useWindowSize } from "../utils";

import { BadgerFooter } from "./Landing";

const FLIPPED_BABY_META = babyBadgerMetadata.reverse();

const FamilySearch = () => {
  const [selectedB, _selectedB] = useState<BADGER_TYPE | null>(null);
  const [geneticBaby, _geneticBaby] = useState<BABY_BADGER_TYPE | null>(null);
  const [familyBaby, _familyBaby] = useState<BABY_BADGER_TYPE | null>(null);
  const [noBreed, _noBreed] = useState(false);
  const [selectedBadger, _selectedBadger] = useState<BADGER_TYPE | null>(null);

  const renderOptions = () => {
    const selectOptions = badgerMetaData.map((d, i) => {
      const cleaned = d.name.replace(/\D/g, "");
      const _ID = parseInt(cleaned, 10);

      const attributes = d.attributes.map((d, i) => {
        const attr: BADGER_ATTR = {
          value: d.value,
          trait_type: d.trait_type,
        };
        return attr;
      });

      const _editBadge: BADGER_TYPE = {
        ...d,
        imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmWtLcsypXdSkZcqXJSjozFRvvK3Q3Hp5qBBLyuEBohtJ2/${
          d.name || ""
        }.gif`,
        id: _ID,
        attributes: attributes,
      };

      const thigns = String(_ID).padStart(4, "0");

      return {
        value: thigns,
        label: thigns,
        meta: _editBadge,
      };
    });
    return selectOptions;
  };

  const hanldeChange = (things: any) => {
    console.log("hanldeChange", things);
    _familyBaby(null);
    _geneticBaby(null);
    _noBreed(false);
    //_hasBeenBreed(null);
    const badger = things.meta as BADGER_TYPE;
    _selectedBadger(badger);
    //_selectedB(badger);

    hanldeFetchBadgerStatus(badger);
  };

  const hanldeFetchBadgerStatus = async (badger: BADGER_TYPE) => {
    console.log("is this runnings");
    try {
      const id = badger.name.replace(/\D/g, "");
      const _ID = parseInt(id, 10);

      const genBaby = babyBadgerMetadata.find((d, i) => {
        const parent_1 = d.parent_1;
        const parent_2 = d.parent_2;

        if (_ID === parent_1 || _ID === parent_2) {
          return true;
        }
      });
      const otherItem = actual.find((d, i) => {
        return d.parent === _ID;
      });

      console.log("oter", otherItem);
      console.log("genBaby", genBaby);
      if (genBaby && genBaby !== null && genBaby !== undefined && otherItem) {
        console.log("genBaby again inside check", genBaby);

        const famBaby = FLIPPED_BABY_META[genBaby.baby_id];
        console.log("famBaby", famBaby);

        const geneticBaby: BABY_BADGER_TYPE = {
          name: otherItem.baby + "" || "",
          baby_id: otherItem ? otherItem?.baby : 1,
          imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmQbyehKTTczgB7n7GwVcLir9tmtRKEtrag3M8FEcKhvTT/${
            otherItem ? otherItem.baby : ""
          }.gif`,
          parent_1: genBaby ? genBaby?.parent_1 : 1,
          parent_2: genBaby ? genBaby?.parent_2 : 2,
        };

        const familyBaby: BABY_BADGER_TYPE = {
          name: famBaby.baby_id + 1 + "" || "",
          baby_id: famBaby ? famBaby.baby_id + 1 : 1,
          imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmQbyehKTTczgB7n7GwVcLir9tmtRKEtrag3M8FEcKhvTT/${
            famBaby ? famBaby.baby_id + 1 : ""
          }.gif`,
          parent_1: famBaby ? famBaby?.parent_1 : 1,
          parent_2: famBaby ? famBaby?.parent_2 : 1,
        };

        _geneticBaby(geneticBaby);
        _familyBaby(familyBaby);
      } else {
        console.log(" did we make it here");
        _noBreed(true);
      }
    } catch (err) {
      console.log("hanldeFetchBadgerStatus err", err);
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,

          zIndex: 99,
          width: "90vw",
          height: "90vh",
          overflow: "hidden",
        }}
      >
        <div className="heart x1"></div>
        <div className="heart x2"></div>
        <div className="heart x3"></div>
        <div className="heart x4"></div>
        <div className="heart x5"> </div>
        <div className="altheart x6"></div>
      </div>

      <ColumnFlex
        style={{
          minHeight: "80vh",
          width: "90vw",
          position: "relative",
          zIndex: 100,
        }}
        paddingTop={0}
        padding={1}
        alignItems={"center"}
        justifyContent="center"
        justifyItems={"center"}
        margin="auto"
      >
        <ToastContainer enableMultiContainer containerId={"verify"} />

        <RowFlex
          justifyContent={"center"}
          maxWidth={"1400px"}
          width="100%"
          padding={"00px 0 0 0"}
          flexDirection={{
            _: "column",
            md: "column",
          }}
          justifyItems={{
            _: "center",
          }}
          alignItems={"center"}
        >
          <ColumnFlex
            maxWidth={"640px"}
            padding={2}
            paddingLeft={{
              _: 2,
              lg: 5,
            }}
            style={{
              borderRadius: "10px",
              textAlign: "center",
            }}
            alignItems={"start"}
            justifyContent="center"
            justifyItems={"center"}
            maxHeight="880px"
          >
            <RowFlex margin={"0px 0 20px 0"} width={"100%"}>
              <LabelHeading
                fontFamily="Poppins,sans-serif;"
                fontWeight={700}
                color={Colors.brightPink}
              >
                Baby
              </LabelHeading>
              <LabelHeading
                fontFamily="Poppins,sans-serif;"
                fontWeight={700}
                style={{
                  // margin: "0 8px 0 8px",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
                color={Colors.burgundy}
              >
                Family
              </LabelHeading>
              <LabelHeading
                fontFamily="Poppins,sans-serif;"
                fontWeight={700}
                color={Colors.brightPink}
              >
                Finder
              </LabelHeading>
            </RowFlex>

            <TextHeading
              fontFamily="Poppins,sans-serif;"
              color={Colors.burgundy}
            >
              Search By Parent ID
            </TextHeading>
            <div
              style={{ margin: "10px 0 0 0", width: "100%", height: "80px" }}
            >
              <Select
                onChange={hanldeChange}
                isSearchable
                options={renderOptions()}
                placeholder=""
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    width: "100%",
                    maxWidth: "527px",
                    minHeight: "60px",
                    borderRadius: "10px",
                    border: `solid 1px ${Colors.brightPink}`,
                  }),

                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
            {/* <div
            style={{
              border: `solid 2px ${Colors.midPink}`,
              padding: "0px",
              borderRadius: "12px",
              height: "auto",
              //width: "350px",
              width: "100%",
              backgroundColor: Colors.midPink,
            }}
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              <ColumnFlex margin={"10px 5px 0 0"}>
                <SubText color={Colors.burgundy} fontStyle={"italic"}>
                  Rumors of stork fill the air...
                </SubText>
                <SubText color={Colors.burgundy} fontStyle={"italic"}>
                  of cubs misplaced in error
                </SubText>
              </ColumnFlex>
              <ColumnFlex margin={"10px 5px 20px 0"}>
                <SubText color={Colors.burgundy} fontStyle={"italic"}>
                  A labor of love by a mother...
                </SubText>
                <SubText color={Colors.burgundy} fontStyle={"italic"}>
                  Lost, then found by another
                </SubText>
              </ColumnFlex>

              <div
                style={{
                  minHeight: "300px",
                }}
              >
                {geneticBaby && familyBaby ? (
                  <RowFlex
                    width={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      zIndex: 20,
                    }}
                  >
                    <ScratchOff
                      key={geneticBaby ? geneticBaby.baby_id : "0"}
                      height={"200px"}
                      width={"200px"}
                      background={`https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmQbyehKTTczgB7n7GwVcLir9tmtRKEtrag3M8FEcKhvTT/baby_badger_${
                        geneticBaby.baby_id || ""
                      }.gif`}
                      foreground={recBaby}
                      onfinished={() => console.log("on finished item")}
                    />
                  </RowFlex>
                ) : (
                  <RowFlex
                    width={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    style={{}}
                  >
                    <img height={"250px"} width="250px" src={recBaby} />
                  </RowFlex>
                )}
              </div>
            </div>
          </div> */}
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                textAlign: "center",
                minWidth: "300px",
                position: "relative",
                marginTop: "20px",
              }}
            >
              {noBreed && selectedBadger ? (
                <ColumnFlex
                  alignItems={"center"}
                  justifyContent="center"
                  padding={"40px 60px"}
                >
                  <LabelHeading
                    fontFamily="Poppins,sans-serif;"
                    fontWeight={700}
                    color={Colors.brightPink}
                  >
                    {`Lonely Badger Badger ${selectedBadger.id}`}
                  </LabelHeading>
                  <Text>This Badger did not breed</Text>
                  <BasicTileImage
                    imageUrl={
                      selectedBadger
                        ? `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmWtLcsypXdSkZcqXJSjozFRvvK3Q3Hp5qBBLyuEBohtJ2/bitcoin_badger_${
                            selectedBadger.id || ""
                          }.gif`
                        : "notloaded"
                    }
                    name=""
                  />
                </ColumnFlex>
              ) : (
                <>
                  <TriHeading color={Colors.burgundy}>Family Tree</TriHeading>

                  <>
                    <RowFlex
                      alignItems={"center"}
                      justifyContent="center"
                      margin={"0 0 20px 0"}
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          margin: "auto",
                          width: "46%",
                          height: "4px",
                          borderRadius: "8px",
                          position: "absolute",
                          left: "0",
                          right: "0",
                          bottom: "-5%",
                          zIndex: 30,
                          backgroundColor: Colors.brightPink,
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              margin: "auto",
                              width: "100%",
                              height: "4px",

                              position: "absolute",
                              left: "0",
                              right: "0",

                              zIndex: 40,
                              backgroundColor: Colors.brightPink,
                            }}
                          />

                          <div
                            style={{
                              margin: "auto",
                              width: "5pz",
                              height: "20px",
                              borderRadius: "8px",
                              position: "absolute",
                              left: "5px",

                              zIndex: 45,
                              backgroundColor: Colors.brightPink,
                            }}
                          />
                          <div
                            style={{
                              margin: "auto",
                              width: "5pz",
                              height: "20px",
                              borderRadius: "8px",
                              position: "absolute",
                              right: "5px",

                              zIndex: 45,
                              backgroundColor: Colors.brightPink,
                            }}
                          />
                          <div
                            style={{
                              margin: "auto",
                              width: "4px",
                              height: "30px",
                              borderRadius: "2px",
                              position: "absolute",
                              left: "0",
                              right: "0",
                              bottom: "-30px",
                              zIndex: 30,
                              backgroundColor: Colors.brightPink,
                            }}
                          />
                          <div
                            style={{
                              margin: "auto",
                              width: "4px",
                              height: "30px",

                              position: "absolute",
                              left: "0px",

                              top: "-30px",
                              zIndex: 30,
                              backgroundColor: Colors.brightPink,
                            }}
                          />
                          <div
                            style={{
                              margin: "auto",
                              width: "4px",
                              height: "30px",

                              position: "absolute",
                              right: "0px",

                              top: "-30px",
                              zIndex: 30,
                              backgroundColor: Colors.brightPink,
                            }}
                          />
                        </div>
                      </div>
                      <ColumnFlex>
                        <LabelHeading
                          fontFamily="Poppins,sans-serif;"
                          fontWeight={700}
                          color={Colors.brightPink}
                        >
                          {`Badger ${geneticBaby ? geneticBaby.parent_1 : ""}`}
                        </LabelHeading>

                        <BasicTileImage
                          imageUrl={
                            geneticBaby
                              ? `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmWtLcsypXdSkZcqXJSjozFRvvK3Q3Hp5qBBLyuEBohtJ2/bitcoin_badger_${
                                  geneticBaby.parent_1 || ""
                                }.gif`
                              : "notloaded"
                          }
                          name=""
                        />
                      </ColumnFlex>
                      <ColumnFlex>
                        <LabelHeading
                          fontFamily="Poppins,sans-serif;"
                          fontWeight={700}
                          color={Colors.brightPink}
                        >
                          {`Badger ${geneticBaby ? geneticBaby.parent_2 : ""}`}
                        </LabelHeading>
                        <BasicTileImage
                          imageUrl={
                            geneticBaby
                              ? `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmWtLcsypXdSkZcqXJSjozFRvvK3Q3Hp5qBBLyuEBohtJ2/bitcoin_badger_${
                                  geneticBaby.parent_2 || ""
                                }.gif`
                              : "notloaded"
                          }
                          name=""
                        />
                      </ColumnFlex>
                    </RowFlex>
                    <RowFlex
                      padding={"10px 0 0 0"}
                      margin="0 0 20px 0 "
                      alignItems={"center"}
                      justifyContent="center"
                      justifyItems={"center"}
                    >
                      <img
                        src={babyLeftHeart}
                        style={{
                          width: "10%",
                          maxWidth: "200px",
                          minWidth: "100px",
                        }}
                      />
                      <ColumnFlex alignItems={"center"}>
                        <LabelHeading
                          fontFamily="Poppins,sans-serif;"
                          fontWeight={700}
                          color={Colors.brightPink}
                        >
                          {` Baby Badger ${
                            familyBaby ? familyBaby.baby_id : ""
                          }`}
                        </LabelHeading>
                        <BasicTileImage
                          imageUrl={
                            familyBaby
                              ? `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmQbyehKTTczgB7n7GwVcLir9tmtRKEtrag3M8FEcKhvTT/baby_badger_${
                                  familyBaby.baby_id || ""
                                }.gif`
                              : "notloaded"
                          }
                          name=""
                        />
                      </ColumnFlex>
                      <img
                        style={{
                          width: "10%",
                          maxWidth: "200px",
                          minWidth: "100px",
                        }}
                        src={babyRightHeart}
                      />
                    </RowFlex>
                  </>
                </>
              )}
            </div>
          </ColumnFlex>
        </RowFlex>
      </ColumnFlex>
      <BadgerFooter />
    </>
  );
};

export default FamilySearch;
